import React from 'react';

import {
  LayoutType,
  NativeAdObj,
  Vertical,
  PlacementId,
  AdStatus
} from '@schibsted-nmp/advertising-shared';
import { getAdConfig } from '@client/core/state/reducer';
import { useLoadNativeAd } from '@client/core/hooks';

import NativeCogwheel from './parts/NativeCogwheel';
import { NativeAdSwitch } from './NativeAdSwitch';

export type Props = {
  adObj: NativeAdObj;
  targetId: string;
  placementId: PlacementId;
  className?: string;
  status: AdStatus;
  layoutType?: LayoutType;
  pageType?: string;
  vertical?: string;
  isRecircAd?: boolean;
};

function getExtraInset(
  pageType: string,
  vertical: Vertical,
  isRecircAd: boolean,
  layoutType?: LayoutType
): string {
  if (layoutType === 'list') return '0';
  if (isRecircAd) return '8';
  const extraCogwheelInset: Vertical[] = ['car', 'mobility'];
  return extraCogwheelInset?.includes(vertical) ? '10' : '0';
}

export default function NativeAdComponent(props: Props) {
  const { targetId, adObj, status, layoutType } = props;
  const { vertical, pageType } = getAdConfig();

  useLoadNativeAd(adObj, targetId, status);

  const isRecircAd =
    targetId.includes('recirc') || targetId.includes('ad-stream');
  return (
    <div id={targetId}>
      <NativeAdSwitch
        {...props}
        vertical={vertical}
        pageType={pageType}
        isRecircAd={isRecircAd}
      />
      <NativeCogwheel
        extraInset={getExtraInset(pageType, vertical, isRecircAd, layoutType)}
        adObj={adObj}
      />
    </div>
  );
}
