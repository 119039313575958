import cn from 'classnames';
import { html, LitElement, PropertyValues } from 'lit';

import {
  AdServer,
  LayoutType,
  PlacementId,
  debugLog,
  RecommendationCategories,
  AdType
} from '@schibsted-nmp/advertising-shared';
import { AdSwitchProps } from '@client/xandr/components/adSwitch/AdSwitchComponent';
import { AdVendor } from '@client/core/AdVendor';
import { GamAdVendor } from '@client/adManager/GamAdVendor';
import { AfsAdVendor } from '@client/adsense/AfsAdVendor';
import { XandrAdVendor } from '@client/xandr/XandrAdVendor';
import { AdnAdVendor } from '@client/adnuntius/AdnAdVendor';
import { $placementsAtom } from '@client/core/atoms/placements';
import { EmptyAdVendor } from '@client/core/EmptyAdVendor';

import AdvtComponentStyles, {
  getClassNameByStatus,
  getClassNameByType
} from './AdvtComponentStyles';
import { $adContainerStyleMap } from '../../atoms/adContainerStyleMap';

const NAME = 'advt-component';

class AdvtComponent extends LitElement implements AdSwitchProps {
  // Properties with default values or required indicators
  containerId: string;

  placementId: PlacementId;

  adIndex?: number;

  categories?: RecommendationCategories;

  initialLayoutType?: LayoutType;

  adVendorInstance: InstanceType<typeof AdVendor>;

  private _adTypeClassName: string;

  private _originalClassName: string;

  private _statusClassName: string;

  private _typeClassName: string;

  static get properties() {
    return {
      containerId: { type: String },
      placementId: { type: String },
      initialLayoutType: { type: String },
      adIndex: { type: Number },
      categories: { type: Object }
    };
  }

  constructor() {
    super();
    this.placementId = PlacementId.Empty;
    this.containerId = ``;
    this.adIndex = 0;
    this._adTypeClassName = '';
    this._originalClassName = this.className;
    this._statusClassName = '';
    this._typeClassName = '';
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.adVendorInstance.cleanupEvents();
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.initAdVendorInstance();

    $adContainerStyleMap.subscribe(() => {
      const adContainerMap = $adContainerStyleMap.get();
      const containerId = this.adVendorInstance?.containerId;

      if (!containerId || !Object.entries(adContainerMap).length) {
        return;
      }
      const adContainerData = adContainerMap[this.adVendorInstance.containerId];
      if (adContainerData) {
        this._typeClassName = getClassNameByType(
          adContainerData.adType as AdType
        );
        this._statusClassName = getClassNameByStatus(adContainerData.status);
        this.className = cn(
          this._originalClassName,
          this._adTypeClassName,
          this._statusClassName,
          this._typeClassName
        );
      }
    });
    this.adVendorInstance.setupEvents();
  }

  private initAdVendorInstance() {
    const placements = $placementsAtom.get();
    const placement = placements?.find(
      (placement) => placement.placementId === this.placementId
    );
    if (!placement) {
      debugLog('initAdVendorInstance failed. Missing placement from config');
      this.adVendorInstance = new EmptyAdVendor(this.placementId);
      this.className = 'advt-empty';
      return;
    }
    if (placement.adServer.type === AdServer.GAM) {
      this.adVendorInstance = new GamAdVendor(this.placementId);
      this._adTypeClassName = 'advt-g';
      this.className = this._adTypeClassName;
    } else if (placement.adServer.type === AdServer.AFS) {
      this.adVendorInstance = new AfsAdVendor(this.placementId);
      this.adVendorInstance.placementId = this.placementId;
      this._adTypeClassName = 'advt-g';
      this.className = this._adTypeClassName;
    } else if (placement.adServer.type === AdServer.Adn) {
      this.adVendorInstance = new AdnAdVendor(this.placementId);
      this._adTypeClassName = 'advt-adn';
      this.className = this._adTypeClassName;
    } else {
      this.adVendorInstance = new XandrAdVendor(
        this.placementId,
        this.containerId,
        this.adIndex || 0,
        this.initialLayoutType || 'list',
        this.categories
      );
      this._adTypeClassName = 'advt-x';
      this.className = this._adTypeClassName;
    }
    debugLog(
      `AdvtComponent: Using ${this.adVendorInstance.adServer} for ${this.placementId}`
    );
  }

  private makeSlotAvailableFromLightDOM(placementId: PlacementId) {
    const containerId =
      this.adVendorInstance.adServer === AdServer.AFS
        ? placementId
        : `${placementId}--container`;

    if (this.querySelector(`#${containerId}`)) {
      // If the container is already in the surrounding DOM, do nothing. This
      // check makes the component backwards compatible when the slotted
      // container is added by the component user.
      //
      // This is necessary because the component is used in two ways:
      // 1. The component user adds the container to the light DOM. This is the
      //    recommended way of using the component. One reason for this is to avoid
      //    hydration mismatch issues when using the component with React SSR.
      // 2. The component user does not add the container to the light DOM.
      //    This is the legacy way of using the component. The component adds the
      //    container to the light DOM itself, in order to be backwards compatible.
      return;
    }

    const slotDiv = document.createElement('div');
    slotDiv.slot = `${placementId}--slot`;
    slotDiv.id = containerId;

    // add the div with slot available in the light DOM
    this.appendChild(slotDiv);
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.makeSlotAvailableFromLightDOM(this.adVendorInstance.placementId);
    if (
      this.adVendorInstance.adServer === AdServer.Xandr ||
      this.adVendorInstance.adServer === AdServer.GAM ||
      this.adVendorInstance.adServer === AdServer.Adn
    ) {
      this.adVendorInstance.requestAd();
    }
  }

  static styles = AdvtComponentStyles;

  render() {
    if (this.adVendorInstance) {
      return this.adVendorInstance.render();
    }
    return html``;
  }
}
export function defineAdvtComponent() {
  if (!customElements.get(NAME)) {
    customElements.define(NAME, AdvtComponent);
  }
}
