import { getAdConfig, isFeatureEnabled } from '@client/core/state/reducer';
import {
  debugLog,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';

import { $gamTargetingAtom } from '../atoms/config';
import { getConfigId } from './config';

// eslint-disable-next-line consistent-return
export function loadRelevantDigitalPrebid() {
  const enableGamPrebid = isFeatureEnabled(
    UNLEASH_FEATURE_NAME.enableGamPrebid
  );

  if (enableGamPrebid && typeof window !== 'undefined') {
    window.relevantDigital = window.relevantDigital || {};
    window.relevantDigital.cmd = window.relevantDigital.cmd || [];

    const configId = configIdByBrand();
    if (!configId) return;

    debugLog('Loading Relevant Digital Prebid for GAM');
    window.relevantDigital.cmd.push(() => {
      window.relevantDigital.loadPrebid({
        configId,
        manageAdserver: true,
        collapseEmptyDivs: true,
        noGpt: true,
        collapseBeforeAdFetch: false,
        allowedDivIds: null, // set to an array to only load certain <div>s, example - ["divId1", "divId2"]
        noSlotReload: false, // set true to only load ad slots that have never been loaded
        delayedAdserverLoading: true,
        onSlotAndUnit: ({ slot }) => {
          slot.waitGamSlot((gamSlot) => {
            const currentTargeting = $gamTargetingAtom.get() || [];
            currentTargeting.forEach((kv) => {
              gamSlot.setTargeting(kv.key, kv.value);
            });
          });
        }
      });
    });
  }
}

// Get config ID by brand and device type
function configIdByBrand() {
  const brand = getAdConfig().brand || 'tori';
  const deviceType = getAdConfig().deviceType || 'desktop';

  return getConfigId(brand, deviceType);
}
