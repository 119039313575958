import React from 'react';

export function Label({
  className,
  classes,
  adLabel
}: {
  classes: {
    badge: string;
    neutral: string;
  };
  adLabel: string;
  className?: string;
}) {
  return (
    <span className={`${classes.badge} ${classes.neutral} ${className}`}>
      {adLabel}
    </span>
  );
}

export function CustomLabel({
  adLabel,
  insetClassName = 'top-8 left-8'
}: {
  adLabel: string;
  insetClassName?: string;
}) {
  return (
    <span
      className={`absolute ${insetClassName} py-4 px-8 text-12 font-bold rounded-tl-8 rounded-br-4`}
      style={{
        background: '#f0f0f2'
      }}
    >
      {adLabel}
    </span>
  );
}
